<template>
  <div>
    <!-- 工程登记 -->
    <div class="content">
      <div class="all">
        <div class="SearchBar">
          <!--搜索框-->
          <el-form
            ref="search"
            :inline="true"
            :model="search"
            size="small"
            label-width="115px"
          >
            <el-form-item label="工程编号/名称" label-width="110px">
              <el-input
                v-model="search.numberOrName"
                placeholder="请输入"
                class="SearchInput"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="工程类型" label-width="80px">
              <el-select
                v-model="search.projectType"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in TypeList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工程状态" label-width="80px">
              <el-select v-model="search.status" clearable placeholder="请选择">
                <el-option
                  v-for="item in statusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报装工程项目" label-width="100px">
              <el-select v-model="search.bz" clearable placeholder="请选择">
                <el-option
                  v-for="item in dataList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="110px" label="登记时间">
              <el-date-picker
                v-model="searchDatatime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                @change="inquire"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label-width="20px" label=" ">
              <div>
                <el-button type="primary" size="small" @click="getList(1)"
                  >查询</el-button
                >
                <el-button type="primary" plain size="small" @click="reset"
                  >重置</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="table">
          <div style="display: flex; justify-content: flex-end">
            <el-button type="primary" @click="showadd" size="small" plain
              >新建工程</el-button
            >
          </div>
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 445px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="projectType"
              label="工程类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="contractId"
              label="施工合同"
              align="center"
              show-overflow-tooltip
              width="100"
            >
              <template slot-scope="{ row }">
                <!-- <a :href="row.contractAttachment"  target="_back">查看</a> -->
                <!-- <a :href="row.contractAttachment" download="" target="_back">查看</a> -->
                <el-button size="small" @click="showImg(row)">查看</el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderCode"
              label="报装工单"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="realname"
              label="登记人"
              align="center"
              width="100"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="登记时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="status"
              label="工程状态"
              align="center"
              show-overflow-tooltip
              width="100"
            >
              <template slot-scope="{ row }">
                <el-tag
                  v-if="row.status"
                  :type="
                    row.status[row.status.length - 1] == '中'
                      ? 'warning'
                      : row.status[row.status.length - 1] == '成'
                      ? 'success'
                      : ''
                  "
                  >{{ row.status }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="operation"
              label="操作"
              align="left"
              width="250px"
            >
              <template slot-scope="{ row }">
                <el-button type="primary" size="small" @click="desc(row)"
                  >查看</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="showedit(row)"
                  v-if="row.status == '已登记'"
                  >编辑</el-button
                >
                <el-button type="primary" size="small" @click="apply(row)"
                  >验收范围</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="search.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="search.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 新建工程 -->
    <el-dialog
      :title="titledes == 'add' ? '新建工程' : '编辑工程'"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form ref="addform" :model="form" :rules="rule" size="small">
        <div>
          <el-form-item
            label="工程编号"
            prop="projectNumber"
            label-width="130px"
          >
            <el-input
              v-model="form.projectNumber"
              placeholder="请输入"
              :style="breadth"
              maxlength="100"
              class="SearchInput"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="工程名称" prop="projectName" label-width="130px">
            <el-input
              v-model="form.projectName"
              placeholder="请输入"
              :style="breadth"
              maxlength="100"
              class="SearchInput"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="关联合同" prop="contractId" label-width="130px">
            <el-select
              v-model="form.contractId"
              placeholder="请选择"
              filterable
              :style="breadth"
              value-key="id"
              :disabled="form.status == 2"
              style="width: 173px"
            >
              <el-option
                v-for="item in teamList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工程类型" prop="projectType" label-width="130px">
            <el-select
              v-model="form.projectType"
              placeholder="请选择"
              filterable
              :style="breadth"
              value-key="id"
              style="width: 173px"
            >
              <el-option
                v-for="item in TypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工程地点" prop="projectSite" label-width="130px">
            <el-input
              v-model="form.projectSite"
              placeholder="请输入"
              :style="breadth"
              maxlength="100"
              class="SearchInput"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            label="工程设计"
            prop="isDesign"
            v-if="form.projectType != 547"
            label-width="130px"
          >
            <el-radio-group v-model="form.isDesign">
              <el-radio :label="1">需要</el-radio>
              <el-radio :label="0">不需要</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="验收范围" label-width="120px"
            ><span style="color: red; margin-left: -10px; margin-right: 10px"
              >*</span
            >
            <el-checkbox
              v-model="form.isMainLine"
              name="type"
              :true-label="1"
              :false-label="0"
              v-if="form.projectType != 547"
              @change="mains"
              >外网验收-主管线</el-checkbox
            >
            <br v-if="form.projectType != 547" /><span
              style="margin-left: 20px"
              v-if="form.projectType != 547"
            ></span>
            <el-checkbox
              v-model="form.isPressure"
              name="type"
              :true-label="1"
              :false-label="0"
              @change="onbottom"
              v-if="form.projectType != 547"
              :disabled="form.isMainLine != 1"
              >看压吹扫</el-checkbox
            >
            <br v-if="form.projectType != 547" /><span
              style="margin-left: 20px"
              v-if="form.projectType != 547"
            ></span
            ><span
              style="margin-left: 20px"
              v-if="form.projectType != 547"
            ></span>
            <el-checkbox
              v-model="form.isMediumPressure"
              name="type"
              :true-label="1"
              :false-label="0"
              v-if="form.projectType != 547"
              :disabled="form.isPressure != 1"
              >中压</el-checkbox
            >
            <el-checkbox
              v-model="form.isLowPressure"
              name="type"
              :true-label="1"
              :false-label="0"
              v-if="form.projectType != 547"
              :disabled="form.isPressure != 1"
              >低压</el-checkbox
            >
            <br v-if="form.projectType != 547" /><span
              style="margin-left: 20px"
              v-if="form.projectType != 547"
            ></span>
            <el-checkbox
              v-model="form.isRadiation"
              name="type"
              :true-label="1"
              :false-label="0"
              v-if="form.projectType != 547"
              :disabled="form.isMainLine != 1"
              >敷设环境</el-checkbox
            >
            <br v-if="form.projectType != 547" /><span
              style="margin-left: 20px"
              v-if="form.projectType != 547"
            ></span>
            <el-checkbox
              v-model="form.isMarker"
              name="type"
              :true-label="1"
              :false-label="0"
              v-if="form.projectType != 547"
              :disabled="form.isMainLine != 1"
              >地面标志物</el-checkbox
            >
            <br v-if="form.projectType != 547" />
            <el-checkbox v-model="form.type" name="type" @change="indoorClick">室内验收</el-checkbox>
            <br /><span
              style="margin-left: 20px"
              v-if="form.projectType != 547"
            ></span>
            <el-radio-group v-model="form.indoorMode" @change="typeSelect">
              <el-radio :label="1" v-if="form.projectType != 547"
                >工商福验收</el-radio
              >
              <el-radio :label="2">居民补户验收</el-radio>
              <el-radio :label="3" v-if="form.projectType != 547"
                >城区集体验收</el-radio
              >
              <el-radio :label="4" v-if="form.projectType != 547"
                >村村通集体验收</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="工程说明" prop="applyId" label-width="130px">
            <el-input
              v-model="form.projectExplain"
              placeholder="请输入"
              :style="breadth"
              maxlength="100"
              class="SearchInput"
              type="textarea"
              clearable
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right: 10px" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="saveadd">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 验收范围 -->
    <el-dialog
      title="验收范围"
      :visible.sync="dialogVisibleApply"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('rangeform')"
    >
      <el-form
        ref="Applyform"
        :model="rangeform"
        label-width="120px"
        size="mini"
        :rules="rulesApp"
      >
        <div>
          <el-form-item label="验收范围">
            <el-checkbox
              v-model="rangeform.isMainLine"
              v-if="rangeform.projectType != 547"
              name="type"
              :true-label="1"
              :false-label="0"
              @change="rangemains"
              >外网验收-主管线</el-checkbox
            >
            <br v-if="rangeform.projectType != 547" /><span
              style="margin-left: 20px"
              v-if="rangeform.projectType != 547"
            ></span>
            <el-checkbox
              v-model="rangeform.isPressure"
              v-if="rangeform.projectType != 547"
              name="type"
              :true-label="1"
              :false-label="0"
              @change="onrangebottom"
              :disabled="rangeform.isMainLine != 1"
              >看压吹扫</el-checkbox
            >
            <br v-if="rangeform.projectType != 547" /><span
              style="margin-left: 20px"
              v-if="rangeform.projectType != 547"
            ></span
            ><span style="margin-left: 20px"></span>
            <el-checkbox
              v-model="rangeform.isMediumPressure"
              v-if="rangeform.projectType != 547"
              name="type"
              :true-label="1"
              :false-label="0"
              :disabled="rangeform.isPressure != 1"
              >中压</el-checkbox
            >
            <el-checkbox
              v-model="rangeform.isLowPressure"
              v-if="rangeform.projectType != 547"
              name="type"
              :true-label="1"
              :false-label="0"
              :disabled="rangeform.isPressure != 1"
              >低压</el-checkbox
            >
            <br v-if="rangeform.projectType != 547" /><span
              style="margin-left: 20px"
              v-if="rangeform.projectType != 547"
            ></span>
            <el-checkbox
              v-model="rangeform.isRadiation"
              v-if="rangeform.projectType != 547"
              name="type"
              :true-label="1"
              :false-label="0"
              :disabled="rangeform.isMainLine != 1"
              >敷设环境</el-checkbox
            >
            <br v-if="rangeform.projectType != 547" /><span
              style="margin-left: 20px"
              v-if="rangeform.projectType != 547"
            ></span>
            <el-checkbox
              v-model="rangeform.isMarker"
              v-if="rangeform.projectType != 547"
              name="type"
              :true-label="1"
              :false-label="0"
              :disabled="rangeform.isMainLine != 1"
              >地面标志物</el-checkbox
            >
            <br v-if="rangeform.projectType != 547" />
            <el-checkbox
              v-model="rangeform.indoor"
              @change="handleCheckedCitiesChange"
              >室内验收</el-checkbox
            >
            <br /><span style="margin-left: 20px"></span>
            <el-radio-group
              v-model="rangeform.indoorMode"
              @change="typerangeSelect"
              :disabled="!rangeform.indoor"
            >
              <el-radio :label="1" v-if="rangeform.projectType != 547"
                >工商福验收</el-radio
              >
              <el-radio :label="2">居民补户验收</el-radio>
              <el-radio :label="3" v-if="rangeform.projectType != 547"
                >城区集体验收</el-radio
              >
              <el-radio :label="4" v-if="rangeform.projectType != 547"
                >村村通集体验收</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          style="margin-right: 10px"
          @click="dialogVisibleApply = false"
          >取消</el-button
        >
        <el-button type="primary" @click="saveApply">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
      :visible.sync="dialogVisibleDesc"
      width="50%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
      <el-form
        :inline="true"
        ref="addform1"
        :model="form"
        label-width="120px"
        size="mini"
      >
        <div>
          <div class="particulars">
            <span class="img"></span>
            <span>工程信息</span>
            <div class="state">{{ objData.status }}</div>
          </div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="工程编号：" prop="positionName">
                {{ objData.projectNumber }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工程名称：" prop="positionName">
                {{ objData.projectName }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工程类型：" prop="positionName">
                {{ objData.projectType }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工程设计：" prop="positionName">
                {{ objData.isDesign == 1 ? "是" : "否" }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工程地点：" prop="positionName">
                {{ objData.projectSite }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="验收范围：" prop="positionName">
                {{ objData.acceptScope }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="工程说明：" prop="positionName">
                {{ objData.projectExplain }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="登记人：" prop="positionName">
                {{ objData.realname }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="登记时间：" prop="positionName">
                {{ objData.createTime }}
              </el-form-item>
            </el-col>
          </el-row>
          <div>
            <div class="particulars">
              <span class="img"></span>
              <span>合同信息</span>
            </div>
            <el-row v-if="objData.contractFrom == 1">
              <el-col :span="24">
                <el-form-item label="报装工单">
                  {{ objData.orderCode }}
                  <el-button type="text" @click="details(objData.orderCode)"
                    >查看详情</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="合同附件:" prop="positionCode">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="
                      objData.contractAttachment &&
                      objData.contractAttachment.split(',').length > 0
                        ? objData.contractAttachment.split(',')[0]
                        : ''
                    "
                    :preview-src-list="objData.contractAttachment.split(',')"
                  >
                  </el-image>
                  <span style="margin-left: 10px"
                    >共{{
                      objData.contractAttachment &&
                      objData.contractAttachment.split(",").length > 0
                        ? objData.contractAttachment.split(",").length
                        : 0
                    }}张</span
                  >
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="缴费佐证:" prop="positionCode">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="
                      objData.payProof && objData.payProof.split(',').length > 0
                        ? objData.payProof.split(',')[0]
                        : ''
                    "
                    :preview-src-list="objData.payProof.split(',')"
                  >
                  </el-image>
                  <span style="margin-left: 10px"
                    >共{{
                      objData.payProof && objData.payProof.split(",").length > 0
                        ? objData.payProof.split(",").length
                        : 0
                    }}张</span
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="签约说明">
                  {{ objData.signDescription }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="签约人">
                  {{ objData.signRealname }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="签约时间">
                  {{ objData.signTime }}
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button style="margin-right:10px" @click="dialogVisibleDesc = false">取消</el-button> -->
      </div>
      <el-dialog
        title="工单信息"
        :visible.sync="orderVisible"
        append-to-body
        width="20%"
      >
        <div class="info-content">
          <el-row class="info-item">
            <span class="label">工单单号：</span>
            <span class="content-item">{{ orderInfo.orderCode }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">用户类型：</span>
            <span class="content-item">{{ orderInfo.userType }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">用气设备：</span>
            <span class="content-item">{{ orderInfo.equipment }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">执行位置：</span>
            <span class="content-item">{{ orderInfo.address }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">执行简图：</span>
            <span class="content-item">
              <el-image
                style="width: 100px; height: 100px"
                :src="orderInfo.firstImageUrl"
                :preview-src-list="orderInfo.imageUrl"
              >
              </el-image>
              <span v-if="orderVisible"
                >共{{
                  orderInfo.imageUrl ? orderInfo.imageUrl.length : 0
                }}张</span
              >
            </span>
          </el-row>
          <el-row class="info-item">
            <span class="label">测量员：</span>
            <span class="content-item">{{ orderInfo.realname }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">创建时间：</span>
            <span class="content-item">{{ orderInfo.createTime }}</span>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="orderVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </el-dialog>
    <show-img :imgList="imgList" ref="imgList"></show-img>
  </div>
</template>
<script>
import {
  projectList,
  projectStatus,
  registerSubmit,
  showContract,
  registerSave,
  showProject,
  acceptScope,
  getRegister,
  registerUpdate,
  showContractFor,
  showContractForUpdate,
} from "@/RequestPort/earlier/index.js";
import { getDict } from "@/apis/commonType.js";
import axios from "@/apis/contractApi";
import showImg from "@/components/showImg.vue";
export default {
  components: { showImg },
  name: "conntract",
  mixins: [],
  data() {
    return {
      search: { current: 1, size: 100 }, //搜索
      searchContractTypeChildIdArr: [],
      // 时间组
      searchDatatime: [],
      total: 30,
      tableData: [], //列表对象
      statusList: [],
      TypeList: [],
      teamList: [],
      // 只要没有进去验收中，可以调整！同时，进入验收中，只要没有相关的验收业务数据产生，可以删除；同时可增加新的验收
      disabled: false,
      dialogVisible: false,
      // 新增编辑输入框宽度
      breadth: "width:300px",
      titledes: "add",
      // 表单提交
      form: {
        indoorMode:0,
        isMainLine: 0,
        isPressure: 0,
        isMediumPressure: 0,
        isLowPressure: 0,
        isRadiation: 0,
        isMarker: 0,
      },
      // 验收范围
      rangeform: {
        indoor: false,
      },
      objData: {
        payProof: "",
        contractAttachment: "",
      },
      rule: {
        contractId: {
          required: true,
          message: "请选择合同编号",
          trigger: "change",
        },
        projectNumber: {
          required: true,
          message: "请输入工程编号",
          trigger: "blur",
        },
        isDesign: {
          required: true,
          message: "请选择工程设计",
          trigger: "change",
        },
        projectName: {
          required: true,
          message: "请输入工程名称",
          trigger: "blur",
        },
        projectType: {
          required: true,
          message: "请选择工程类型",
          trigger: "change",
        },
        projectSite: {
          required: true,
          message: "请输入工程地点",
          trigger: "blur",
        },
        indoorMode: {
          required: true,
          message: "请选择验收",
          trigger: "change",
        },
      },
      rulesApp: {},
      // 查看弹窗
      dialogVisibleDesc: false,
      parentName: "",

      repairBeforFileFormData: new FormData(),
      isturn: 0,
      source: 1,
      // 包装工程
      dataList: [
        { label: "是", value: "1" },
        { label: "否", value: "2" },
      ],
      teamDsc: {},
      // 验收范围
      dialogVisibleApply: false,
      applyName: "",
      applyflag: "",
      // 验收范围（确认弹窗）
      activeName: "接口返回数据",
      orderVisible: false,
      // 工单
      orderInfo: {},
      imgList: [],
    };
  },
  mounted() {
    // 工程状态
    this.getprojectStatus();
    // 登记列表
    this.getList();
  },
  methods: {
    mains(val) {
      if (val == 0) {
        this.form.isPressure = 0;
        this.form.isMediumPressure = 0;
        this.form.isLowPressure = 0;
        this.form.isRadiation = 0;
        this.form.isMarker = 0;
      }
    },
    /**
     * 点击室内验收的复选框的时候
     */
    indoorClick(val){
      if(!val){
        this.form.indoorMode = 0
      } 
    },
    showImg(row) {
      let file = row.contractAttachment.split(",");

      if (file && file.length > 0) {
        let str = file[0];
        if (str.substring(str.length - 4) == ".pdf") {
          file.forEach((item) => {
            this.downfile(item);
          });
        } else {
          this.$refs.imgList.setdialogVisible(true);
          this.imgList = file;
        }
      } else {
        this.$message.error("暂无文件");
      }
    },
    onbottom(val) {
      if (val == 0) {
        this.form.isMediumPressure = 0;
        this.form.isLowPressure = 0;
      }
    },
    rangemains(val) {
      if (val == 0) {
        this.rangeform.isPressure = 0;
        this.rangeform.isMediumPressure = 0;
        this.rangeform.isLowPressure = 0;
        this.rangeform.isRadiation = 0;
        this.rangeform.isMarker = 0;
      }
    },
    onrangebottom(val) {
      if (val == 0) {
        this.rangeform.isMediumPressure = 0;
        this.rangeform.isLowPressure = 0;
      }
    },
    inquire() {
      this.search.current = 1;
      if (this.times && this.times.length > 0) {
        this.search.startTime = this.times[0];
        this.search.endTime = this.times[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    /**
     * 选择
     */
    typerangeSelect(val) {
      this.rangeform.indoor = true;
      // 工商福
      this.rangeform.isIndustry = val == 1 ? 1 : 0;
      // 城区集体验收
      this.rangeform.isCity = val == 3 ? 1 : 0;
      // 村村通集体验收
      this.rangeform.isVillage = val == 4 ? 1 : 0;
    },
    // 选择室内验收
    handleCheckedCitiesChange(val) {
      this.rangeform.indoor = val;
      this.rangeform.indoorMode = 0;
      // 工商福
      this.rangeform.isIndustry = 0;
      // 城区集体验收
      this.rangeform.isCity = 0;
      // 村村通集体验收
      this.rangeform.isVillage = 0;
    },
    /**
     * 工程状态
     */
    getprojectStatus() {
      projectStatus().then((res) => {
        this.statusList = res.data;
      });
      // 工程类型
      getDict({ parentCode: 2100 }).then((res) => {
        this.TypeList = res.data;
      });
    },
    /**
     * 选择
     */
    typeSelect(val) {
      console.log(val)
      this.form.type = true;
      this.form.indoorMode = val
    },
    /**
     * 查询列表
     */
    getList(current) {
      if (this.searchDatatime) {
        this.search.startTime = this.searchDatatime[0];
        this.search.endTime = this.searchDatatime[1];
      }
      if (current) {
        this.search.current = 1;
      }
      //查列表
      projectList(this.search).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    /**
     * 订单查看详情
     */
    async details(orderCode) {
      let that = this;
      //  查询工单信息
      try {
        let res = await axios.getOrderInfo({ orderCode });
        that.orderInfo = res.data;
        that.orderInfo.imageUrl = that.orderInfo.imageUrl.split(",");
        that.orderInfo["firstImageUrl"] =
          that.orderInfo.imageUrl && that.orderInfo.imageUrl.length > 0
            ? that.orderInfo.imageUrl[0]
            : "";
      } catch (e) {}

      this.orderVisible = true;
      // this.$router.push('');
    },
    //重置查询条件
    reset() {
      (this.search = {
        current: 1,
        size: 100,
        dateType: "1",
      }),
        (this.searchContractTypeChildIdArr = []);
      this.searchDatatime = [];
      this.getList(1);
    },

    handleSizeChange(val) {
      this.search.size = val;
      this.getList(1);
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getList();
    },
    /**
     * 新增弹窗
     */
    showadd() {
      this.titledes = "add";
      this.getTeam();
      // 关联合同
      // showContractFor().then(res => {
      //   if (res.code == 200) {
      //     this.teamList = res.data;
      //   }
      // })
      this.form = {
        isMainLine: 0,
        isPressure: 0,
        isMediumPressure: 0,
        isLowPressure: 0,
        isRadiation: 0,
        isMarker: 0,
        type: false,
      };
      this.dialogVisible = true;
    },
    /**
     * 编辑弹窗
     */
    showedit(row) {
      this.titledes = "edit";
      // this.getTeam();
      showContractForUpdate({ id: row.contractId }).then((res) => {
        if (res.code == 200) {
          this.teamList = res.data.map((el) => {
            let name = el.name
              ? el.name + "(" + el.firstParty + ")"
              : el.firstParty;
            return {
              id: el.id,
              name: name,
            };
          });
        }
      });
      getRegister({ id: row.id }).then((res) => {
        this.form = res.data;
        if (this.form.indoorMode) this.form.type = true;
        if (this.form.isMediumPressure == 1 || this.form.isLowPressure == 1)
          this.form.isPressure = 1;
      });
      this.dialogVisible = true;
    },
    getTeam() {
      // apply_applyCodeDic({}).then((res) => {
      //   if (res.code == 200) {
      //     this.teamList = res.data;
      //   }
      // });
      showContract().then((res) => {
        if (res.code == 200) {
          this.teamList = res.data.map((el) => {
            let name = el.name
              ? el.name + "(" + el.firstParty + ")"
              : el.firstParty;
            return {
              id: el.id,
              name: name,
            };
          });
        }
      });
    },
    clearnF() {
      this.form = {};
      this.teamDsc = {};
      this.rangeform = {
        indoor: false,
      };

      if (this.$refs["addform"]) {
        this.$refs["addform"].resetFields();
      }

      //  this.$refs['Applyform'].resetFields();
    },
    /**
     * 新增工程
     */
    saveadd() {
      if (
        this.form.projectType == 547 &&
        this.form.isMainLine != 1 &&
        !this.form.indoorMode
      ) {
        this.$message.error("请选择验收范围");
        return;
      }
      // 选择看压吹扫
      else if (this.form.projectType != 547 && this.form.isPressure == 1) {
        // 中压低压都不选
        if (this.form.isMediumPressure != 1 && this.form.isLowPressure != 1) {
          this.$message.error("请选择验收范围压力");
          return;
        }
      }
      // 不选择看压吹扫
      else if (
        this.form.projectType != 547 &&
        this.form.isPressure != 1 &&
        this.form.isMainLine == 1
      ) {
        // 敷设环境和地标都不选
        if (this.form.isRadiation != 1 && this.form.isMarker != 1) {
          this.$message.error("请选择验收范围主管线");
          return;
        }
      } else if (
        this.form.projectType != 547 &&
        !this.form.indoorMode &&
        this.form.isMainLine != 1
      ) {
        this.$message.error("请选择验收范围");
        return;
      }
      var num = 0;
      if (this.form.isLowPressure == 1) {
        num = num + 1;
      }
      if (this.form.isMediumPressure == 1) {
        num = num + 1;
      }
      if (this.form.isRadiation == 1) {
        num = num + 1;
      }
      if (this.form.isMarker == 1) {
        num = num + 1;
      }
      if (this.form.indoorMode) {
        num = num + 1;
      }
      this.form.acceptCount = num;
      if (this.form.indoorMode) {
        // 工商福
        this.form.isIndustry = this.form.indoorMode == 1 ? 1 : 0;
        // 城区集体验收
        this.form.isCity = this.form.indoorMode == 3 ? 1 : 0;
        // 村村通集体验收
        this.form.isVillage = this.form.indoorMode == 4 ? 1 : 0;
      }
      this.$refs["addform"].validate((valid) => {
        if (valid) {
          const h = this.$createElement;
          let that = this;
          registerSubmit(this.form).then((res) => {
            this.activeName = res.data;

            this.$msgbox({
              title: "请确认工程验收范围：",
              message: h("p", null, [h("span", null, this.activeName)]),
              showCancelButton: true,
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              if (that.titledes == "add") {
                registerSave(this.form).then((res) => {
                  that.$message.success("添加成功");
                  that.dialogVisible = false;
                  that.getList(1);
                });
              } else {
                registerUpdate(this.form).then((res) => {
                  that.$message.success("编辑成功");
                  that.dialogVisible = false;
                  that.getList(1);
                });
              }
            });
          });
        }
      });
    },
    /**
     * 查看
     */
    desc(row) {
      showProject({ id: row.id }).then((res) => {
        if (res.code == 200) {
          this.objData = res.data;
          if (!this.objData.contractAttachment)
            this.objData.contractAttachment = "";
          if (!this.objData.payProof) this.objData.payProof = "";
          this.dialogVisibleDesc = true;
        }
      });
    },
    /**
     * 验收范围
     *
     */
    apply(row, flag) {
      this.rangeform.id = row.id;
      getRegister({ id: row.id }).then((res) => {
        let data = res.data;
        data.isMainLine = data.isMainLine == 2 ? 1 : data.isMainLine;
        data.isPressure = data.isPressure == 2 ? 1 : data.isPressure;
        data.isMediumPressure =
          data.isMediumPressure == 2 ? 1 : data.isMediumPressure;
        data.isLowPressure = data.isLowPressure == 2 ? 1 : data.isLowPressure;
        data.isMarker = data.isMarker == 2 ? 1 : data.isMarker;
        data.isIndustry = data.isIndustry == 2 ? 1 : data.isIndustry;
        data.isCity = data.isCity == 2 ? 1 : data.isCity;
        data.isVillage = data.isVillage == 2 ? 1 : data.isVillage;
        data.isMediumPressure =
          data.isMediumPressure == 2 ? 1 : data.isMediumPressure;
        data.isRadiation = data.isRadiation == 2 ? 1 : data.isRadiation;

        this.rangeform = { ...data, indoor: data.indoorMode ? true : false };
        if (
          this.rangeform.isMediumPressure == 1 ||
          this.rangeform.isLowPressure == 1
        )
          this.rangeform.isPressure = 1;
      });
      this.dialogVisibleApply = true;
    },
    /**
     * 验收范围
     */
    saveApply() {
      if (
        this.rangeform.projectType == 547 &&
        this.rangeform.isMainLine != 1 &&
        !this.rangeform.indoorMode
      ) {
        this.$message.error("请选择验收范围");
        return;
      }
      // 选择看压吹扫
      else if (
        this.rangeform.projectType != 547 &&
        this.rangeform.isPressure == 1
      ) {
        // 中压低压都不选
        if (
          this.rangeform.isMediumPressure != 1 &&
          this.rangeform.isLowPressure != 1
        ) {
          this.$message.error("请选择验收范围压力");
          return;
        }
      }
      // 不选择看压吹扫
      else if (
        this.rangeform.projectType != 547 &&
        this.rangeform.isPressure != 1 &&
        this.rangeform.isMainLine == 1
      ) {
        // 敷设环境和地标都不选
        if (this.rangeform.isRadiation != 1 && this.rangeform.isMarker != 1) {
          this.$message.error("请选择验收范围主管线");
          return;
        }
      } else if (
        this.rangeform.projectType != 547 &&
        !this.rangeform.indoorMode &&
        this.rangeform.isMainLine != 1
      ) {
        this.$message.error("请选择验收范围");
        return;
      }
      var num = 0;
      if (this.rangeform.isLowPressure == 1) {
        num = num + 1;
      }
      if (this.rangeform.isMediumPressure == 1) {
        num = num + 1;
      }
      if (this.rangeform.isRadiation == 1) {
        num = num + 1;
      }
      if (this.rangeform.isMarker == 1) {
        num = num + 1;
      }
      if (this.rangeform.indoorMode) {
        num = num + 1;
      }
      this.rangeform.acceptCount = num;
      if (this.rangeform.indoorMode) {
        // 工商福
        this.rangeform.isIndustry = this.rangeform.indoorMode == 1 ? 1 : 0;
        // 城区集体验收
        this.rangeform.isCity = this.rangeform.indoorMode == 3 ? 1 : 0;
        // 村村通集体验收
        this.rangeform.isVillage = this.rangeform.indoorMode == 4 ? 1 : 0;
      }

      acceptScope(this.rangeform).then((res) => {
        if (res.code == "200") {
          this.$message.success("更改验收范围成功");
          this.getList(1);
          this.dialogVisibleApply = false;
        }
      });
    },
  },
};
</script>

<style  lang="less" scoped>

::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.searchType {
  width: 210px;
  box-shadow: 1px 1px 5px #888888;
  position: absolute;
  z-index: 100;
  background-color: #fff;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;

  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.SearchOptions {
  width: 100%;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}

.all {
  background-color: #f3f4f8;
  padding-top: 0.5%;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 2%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList {
  display: block;
  margin: 10px 0px;
}
.particulars {
  display: flex;
  flex-direction: row;

  height: 40px;
  position: relative;
  .img {
    border: 1px solid #3f74d5;
    height: 20px;
    margin-right: 2px;
  }
  .state {
    width: 60px;
    height: 30px;
    position: absolute;
    top: -10px;
    left: 80px;
    color: #f18d1b;
    line-height: 30px;
    text-align: center;
    border: 1px solid #f18d1b;
  }
}
</style>
